define("discourse/plugins/vsite/discourse/controllers/vsite", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    init() {
      this._super();
      this.set('vsitelist', []);
      // this.fetchBrecs();
      this.getvsitedata();
    },
    getvsitedata() {},
    actions: {
      createVsite(content) {
        if (!content) {
          return;
        }
        const noteRecord = this.store.createRecord('vsite', {
          id: Date.now(),
          content: content
        });
        noteRecord.save().then(result => {
          this.vsitelist.pushObject(result.target);
        }).catch(console.error);
      },
      deleteVsite(item) {
        this.store.destroyRecord('vsite', item).then(() => {
          this.vsitelist.removeObject(item);
        }).catch(console.error);
      }
    }
  });
});