define("discourse/plugins/vsite/initializers/vsite", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initializeVsite(api) {
    const user = api.getCurrentUser();
    const settings = api.container.lookup("site-settings:main");
    /*
    const visibleforgroupnums=settings.vsite_groups.split("|").filter(x=>x.length).map(Number);
    const amInGroupNums=a=>user && user.groups.filter(x=>a.indexOf(x.id)>-1).length>0;
    if (amInGroupNums(visibleforgroupnums)) {
    */
    api.decorateWidget(`hamburger-menu:footerLinks`, () => {
      // footerLinks / generalLinks
      return {
        href: "/vtutorials",
        rawLabel: "Erklärvideos",
        className: "vtutorials-button",
        attributes: {}
      };
    });
    /*
    }
    */
  }
  var _default = _exports.default = {
    name: "vsite",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.31", initializeVsite);
    }
  };
});