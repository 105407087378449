define("discourse/plugins/vsite/discourse/routes/vsite", ["exports", "discourse/routes/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Route für den Pfad `/vtutorials` wie in `../vsite-route-map.js.es6` definiert.
   */
  var _default = _exports.default = _discourse.default.extend({
    renderTemplate() {
      // Renders the template `../templates/vtutorials.hbs`
      this.render('vtutorials');
    }
  });
});