define("discourse/plugins/vsite/discourse/models/vsite", ["exports", "discourse/models/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Has to be implemented for `../controllers/vsite.js.es6` in order to use
   * Discourse’s store properly.
   */
  var _default = _exports.default = _rest.default.extend({
    /**
     * Required when sending PUT requests via Discourse’s store
     */
    updateProperties() {
      return this.getProperties('content');
    }
  });
});